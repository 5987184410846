'use client'

import { Spacer } from '@vinted/web-ui'

import InfoBanner from 'components/InfoBanner'
import TopBanners from 'pages/Home/HomeBanners/TopBanners'
import ShippingFeesAppliedBanner from 'components/ShippingFeesApplyBanner'
import TaxpayersSpecialVerificationSuccessModal from 'components/TaxpayersSpecialVerificationSuccessModal'
import { Screen } from 'constants/tracking/screens'

const HomeBanners = () => {
  return (
    <>
      <TopBanners />
      <TaxpayersSpecialVerificationSuccessModal />
      <InfoBanner screen={Screen.NewsFeed} />
      <ShippingFeesAppliedBanner suffix={<Spacer size={Spacer.Size.X2Large} />} />
    </>
  )
}

export default HomeBanners
