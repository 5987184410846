import { useCallback, useRef, useState } from 'react'

import { useSelector } from 'react-redux'

import Advertisement from 'components/Advertisement'
import { getFirstListedBreakpoint } from 'components/Breakpoint'
import useBreakpoint from 'hooks/useBreakpoint'
import { GenericPromoBoxModel, ListerActivationBannerModel, ClosetModel } from 'types/models'
import { GridItem } from 'types/components'
import { getRandomizedListerActivationBanner } from 'data/utils/banner'
import {
  closetToItem,
  adIndexToItem,
  listerActivationBannerToItem,
  GridItemInsert,
  generateGenericPromoBoxItem,
} from 'data/utils/grid'
import { dismissListerActivationBanners } from 'data/api'
import { getBanners } from 'state/banners/selectors'

import { feedInsertPositions } from '../constants'

export type ItemInsertType =
  | ListerActivationBannerModel
  | ClosetModel
  | GenericPromoBoxModel
  | Partial<ComponentProps<typeof Advertisement>>

type FeedItemInsert = GridItemInsert<
  ItemInsertType,
  string,
  Record<string, Array<GridItem<ItemInsertType>>>
>

type Props = {
  closets: Array<ClosetModel>
  promoBoxes: Array<GenericPromoBoxModel>
}

const useItemInserts = ({ closets, promoBoxes }: Props) => {
  const breakpoints = useBreakpoint()

  const [generateNewListerActivationBanners, setGenerateNewListerActivationBanners] = useState(true)
  const gridBanners = useRef<Array<GridItem<ListerActivationBannerModel>>>([])
  const banners = useSelector(getBanners)

  const generateListerActivationBannerItem = useCallback(
    ({ itemIndex }) => {
      if (gridBanners.current[itemIndex]) return gridBanners.current[itemIndex]
      if (!generateNewListerActivationBanners) return undefined

      const banner = getRandomizedListerActivationBanner(banners.listerActivation)
      const gridBanner = listerActivationBannerToItem(itemIndex, banner)

      gridBanners.current[itemIndex] = gridBanner

      return gridBanner
    },
    [banners.listerActivation, generateNewListerActivationBanners, gridBanners],
  )

  const getItemInserts = useCallback(() => {
    const breakpoint = getFirstListedBreakpoint(breakpoints.active) || 'phones'
    const closetItems = closets.map(closetToItem)

    const activationBannerPositions = {
      first: feedInsertPositions.first[breakpoint],
      distance: feedInsertPositions.distance[breakpoint],
    }

    const closetPromoPositions = {
      first: feedInsertPositions.first[breakpoint],
      distance: feedInsertPositions.distance[breakpoint],
    }

    const adPositions = {
      first: feedInsertPositions.inbetweenAdFirst[breakpoint],
      distance: feedInsertPositions.distance[breakpoint],
    }

    const inserts: Array<FeedItemInsert> = []

    if (banners.listerActivation.length) {
      const bannerInserts = {
        items: {
          banners: [],
        },
        positions: activationBannerPositions,
        generateItem: generateListerActivationBannerItem,
      }

      // `bannerInserts` should be before `closetInserts` as order matters when position is the same
      inserts.push(bannerInserts)
    }

    const closetInserts = {
      items: {
        closet_promotions: closetItems,
      },
      positions: closetPromoPositions,
    }

    inserts.push(closetInserts)

    const adInserts = {
      items: {
        ads: [],
      },
      positions: adPositions,
      generateItem: ({ itemIndex }) => adIndexToItem(itemIndex),
    }

    inserts.push(adInserts)

    const promoBoxPositions = {
      first: feedInsertPositions.promoBoxFirst,
      distance: feedInsertPositions.promoBoxDistance,
    }

    const promoBoxInserts: FeedItemInsert = {
      items: {
        promo_box: [],
      },
      positions: promoBoxPositions,
      generateItem: _ => generateGenericPromoBoxItem(promoBoxes, _),
      forceTruePosition: true,
    }

    inserts.push(promoBoxInserts)

    return inserts
  }, [
    banners.listerActivation.length,
    breakpoints.active,
    closets,
    generateListerActivationBannerItem,
    promoBoxes,
  ])

  const handleBannerDismiss = useCallback((banner: ListerActivationBannerModel) => {
    const lastIndex = gridBanners.current.findIndex(({ data }) => data === banner)

    gridBanners.current = gridBanners.current.slice(0, lastIndex)
    setGenerateNewListerActivationBanners(false)

    dismissListerActivationBanners()
  }, [])

  return {
    getItemInserts,
    handleBannerDismiss,
  }
}

export default useItemInserts
