import { Button, Text } from '@vinted/web-ui'

import Banner from 'components/Banner'
import { TermsAndConditionsBannerModel } from 'types/models'
import { TERMS_URL } from 'constants/routes'

type Props = {
  banner: Pick<TermsAndConditionsBannerModel, 'title' | 'subtitle' | 'buttonTitle'>
}

const TermsAndConditionsBanner = ({ banner }: Props) => {
  return (
    <Banner
      title={<Text text={banner.title} type={Text.Type.Heading} theme="warning" as="h2" />}
      body={<Text text={banner.subtitle} html />}
      actions={[
        <Button text={banner.buttonTitle} url={TERMS_URL} styling={Button.Styling.Filled} />,
      ]}
    />
  )
}

export default TermsAndConditionsBanner
