'use client'

import { Button, Cell, Image, Spacer, Text } from '@vinted/web-ui'
import classNames from 'classnames'
import { useRef } from 'react'
import { InView } from 'react-intersection-observer'

import { BannerLayoutModel } from 'types/models'
import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { Screen } from 'constants/tracking/screens'
import { userClickHomepageElement, userViewHomepageElement } from 'libs/common/event-tracker/events'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'

import HomepageBlockLayout from '../HomepageBlockLayout'

type Props = {
  elements: Array<BannerLayoutModel>
  name: string
  position: number
  homepageSessionId: string
  id: string
}

type BannerProps = {
  banner: BannerLayoutModel
  type: 'wide' | 'narrow'
  handleItemClick(): void
}

type TextTheme = ComponentProps<typeof Text>['theme']

const themeMap = {
  light: 'inverse',
  dark: 'amplified',
}

const Banner = ({ banner, type, handleItemClick }: BannerProps) => {
  const breakpoints = useBreakpoint()

  const { title, icon, cta, background, textTheme, description, foreground } = banner

  const isPhones = breakpoints.phones
  const device = isPhones ? 'mobile' : 'tablet'
  const breakpoint = breakpoints.wide ? 'desktop' : device

  return (
    <div className="single-banner-container">
      <Image
        scaling={Image.Scaling.Cover}
        src={background.url}
        styling={Image.Styling.Rounded}
        color={background.dominantColor}
        alt=""
      />
      {foreground && (
        <div className={`layout-banner-foreground foreground-${type}-${breakpoint}`}>
          <Image scaling={Image.Scaling.Fill} src={foreground.url} alt="" />
        </div>
      )}
      <div className="layout-banner-info-container">
        <Cell
          styling={Cell.Styling.Tight}
          theme="transparent"
          url={cta.url}
          aria={{
            'aria-label': cta.accessibilityLabel || '',
          }}
          onClick={handleItemClick}
          testId="homepage-layouts-banner-cta"
        >
          <div className="layout-banner-info-container-content">
            <div className={`banner-title-and-description-${type}-${breakpoint}`}>
              <Cell
                theme="transparent"
                prefix={icon?.url && <Image src={icon?.url} size={Image.Size.Regular} alt="" />}
                body={
                  title && (
                    <div
                      className={classNames('banner-title-truncation', {
                        'banner-title-mobile': isPhones,
                      })}
                    >
                      <Text
                        text={title}
                        width={Text.Width.Parent}
                        type={isPhones ? Text.Type.Subtitle : undefined}
                        as="h2"
                        theme={themeMap[textTheme] as TextTheme}
                      />
                    </div>
                  )
                }
                styling={Cell.Styling.Tight}
              />
              {(icon || title) && <Spacer />}
              <div
                className={classNames('banner-description-truncation', {
                  'banner-description-mobile': isPhones,
                })}
              >
                <Text
                  text={description}
                  type={isPhones ? Text.Type.Title : Text.Type.Heading}
                  width={Text.Width.Parent}
                  as="p"
                  theme={themeMap[textTheme] as TextTheme}
                  tabIndex={0}
                />
              </div>
            </div>
            <div className="banner-cta">
              <Button
                text={cta.title}
                theme={cta.theme}
                inverse={cta.inverse}
                size={isPhones ? Button.Size.Small : Button.Size.Medium}
                inline
                styling={Button.Styling.Filled}
                truncated
              />
            </div>
          </div>
        </Cell>
      </div>
    </div>
  )
}

const BannersBlock = ({ elements, name, position, homepageSessionId, id }: Props) => {
  const bannerType = elements.length === 1 ? 'wide' : 'narrow'

  const { track } = useTracking()

  const seenBanners = useRef<Array<string>>([])

  const handleItemView = (banner: BannerLayoutModel, index: number) => (inView: boolean) => {
    if (!inView) return

    const seenBanner = banner.id || banner.description
    if (seenBanners.current.includes(seenBanner)) return

    seenBanners.current.push(seenBanner)

    track(
      userViewHomepageElement({
        blockName: name,
        position: index + 1,
        contentSource: banner.contentSource,
        contentSourceLabel: '',
        contentSourceId: banner.id,
        homepageSessionId,
        screen: Screen.NewsFeed,
      }),
    )
  }

  const handleItemClick = (banner: BannerLayoutModel, index: number) => () => {
    track(
      userClickHomepageElement({
        blockName: name,
        position: index + 1,
        contentSource: banner.contentSource,
        contentSourceLabel: '',
        contentSourceId: banner.id,
        homepageSessionId,
        screen: Screen.NewsFeed,
      }),
    )
  }

  const renderBanners = () =>
    elements.map((banner, index) => (
      <InView
        className={classNames({
          'single-banner-container-narrow': bannerType === 'narrow',
        })}
        key={`banner-layout-${index}`}
        onChange={handleItemView(banner, index)}
      >
        <Banner
          banner={banner}
          type={bannerType}
          handleItemClick={handleItemClick(banner, index)}
        />
      </InView>
    ))

  return (
    <HomepageBlockLayout
      name={name}
      position={position}
      homepageSessionId={homepageSessionId}
      id={id}
      body={
        <HorizontalScrollArea controlsScrollType={ControlScrollType.Partial}>
          <div className={`${bannerType}-banners-layout-container`}>{renderBanners()}</div>
        </HorizontalScrollArea>
      }
    />
  )
}

export default BannersBlock
