'use client'

import { useSelector } from 'react-redux'

import { Screen } from 'constants/tracking/screens'
import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import useLocation from 'hooks/useLocation'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'

import PageHead from '../components/Head/PageHead'
import fullPageLayout from '../layouts/FullPage'
import Homepage from '../../app/pages/Home'
import { getLayoutServerSideProps } from '../layouts/FullPage/server-props'
import { transformHomepageSchemaMarkup } from '../libs/seo/schema-markup/transformers'

type Props = {
  visitsCount: number
}

const HomePage = ({ visitsCount }: Props) => {
  const translate = useTranslate()
  const asset = useAsset('/assets/web-logo/default')

  const { baseUrl } = useLocation()
  const isSchemaMarkupEnabled = useSelector(getIsFeatureSwitchEnabled('schema_markup_homepage'))
  const pageTitle = translate('homepage.title')

  const HomepageSchemaMarkup = isSchemaMarkupEnabled
    ? transformHomepageSchemaMarkup({
        meta_title: pageTitle,
        meta_description: translate('main.page_description'),
        baseUrl,
        logo: asset('logo.svg'),
      })
    : undefined

  return (
    <>
      <PageHead title={pageTitle} isIndexed titleSuffix="" jsonLd={HomepageSchemaMarkup} />
      <Homepage visitsCount={visitsCount} />
    </>
  )
}

export default fullPageLayout<Props>(HomePage, {
  shouldBreakoutLayout: true,
})

export const getServerSideProps = getLayoutServerSideProps<Props>(
  (_, serverProps) => {
    const visitsCount = parseFloat(
      serverProps.api.serverCookieManager.get(cookiesDataByName.seller_header_visits) || '0',
    )

    return { props: { visitsCount } }
  },
  { id: 'root', screen: Screen.NewsFeed },
)
