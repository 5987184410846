'use client'

import { Dialog } from '@vinted/web-ui'

import useAbTest from 'hooks/useAbTest'
import useBreakpoint from 'hooks/useBreakpoint'
import { OnboardingModalModel, MultiVariantsOnboardingModalModel } from 'types/models'

import useOnboarding from './hooks/useOnboarding'
import OnboardingVideoVariant from './OnboardingVideoVariant'
import OnboardingVideoActions from './OnboardingVideoActions'
import OnboardingTextVariant from './OnboardingTextVariant'
import OnboardingTextActions from './OnboardingTextActions'

type Props = {
  banner: OnboardingModalModel
  multiVariantsBanner?: MultiVariantsOnboardingModalModel
}

const OnboardingModal = ({ banner, multiVariantsBanner }: Props) => {
  const breakpoints = useBreakpoint()
  const { isOpen, setSlide, onModalClose, onPrimaryClick, onSecondaryClick } = useOnboarding({
    defaultBanner: banner,
    multiVariantsBanner,
  })

  useAbTest({ abTestName: 'first_time_listing_guideline_v3', shouldTrackExpose: true })
  useAbTest({ abTestName: 'promotional_listing_banner_in_search_feed', shouldTrackExpose: true })
  useAbTest({ abTestName: 'multiple_variants_onboarding_modal_v2', shouldTrackExpose: true })

  const contentDimensions = {
    maxWidth: breakpoints.phones ? '400px' : undefined,
    width: breakpoints.phones ? 'calc(100% - 70px)' : undefined,
  }

  const renderContent = () => {
    if (multiVariantsBanner) {
      return (
        <>
          <OnboardingTextVariant
            banner={multiVariantsBanner}
            onSlideChange={setSlide}
            onClose={onModalClose}
          />
          <OnboardingTextActions
            primaryText={multiVariantsBanner.actions.primary.title}
            secondaryText={multiVariantsBanner.actions.secondary.title}
            onPrimaryClick={onPrimaryClick}
            onSecondaryClick={onSecondaryClick}
          />
        </>
      )
    }

    return (
      <>
        <OnboardingVideoVariant banner={banner} onSlideChange={setSlide} onClose={onModalClose} />
        <OnboardingVideoActions onClick={onPrimaryClick} />
      </>
    )
  }

  return (
    <Dialog
      show={isOpen}
      defaultCallback={onModalClose}
      closeOnOverlay
      className="u-overflow-visible u-position-relative u-zindex-bump"
      contentDimensions={multiVariantsBanner ? {} : contentDimensions}
    >
      {renderContent()}
    </Dialog>
  )
}

export default OnboardingModal
