import { range } from 'lodash'

import { getHomepageBatch, getHomepageConfig, getPromoBox } from 'data/api'
import { isResponseSuccessful } from 'libs/utils/api'
import { HomepageBlocksDto } from 'types/dtos'
import { ResponseError } from 'types/api'

import { FetchHomepageBlocksOptions } from '../types'

export const getParallelHomepageBlocks = async (
  options: FetchHomepageBlocksOptions,
): Promise<HomepageBlocksDto | ResponseError<unknown>> => {
  const promoBoxPromise = options.tab.isStickyPromoBoxEnabled ? getPromoBox() : null
  const configResponse = await getHomepageConfig({
    tabName: options.tab.name,
    homepageSessionId: options.homepageSessionId,
  })

  if ('errors' in configResponse) return configResponse

  const homepageSessionId = configResponse.homepage_session_id
  const batchNumbers = range(1, configResponse.batch_count + 1)

  const batchPromises = batchNumbers.map(number =>
    getHomepageBatch(number, {
      tabName: options.tab.name,
      homepageSessionId,
    }),
  )
  const [promoBoxResponse, ...batches] = await Promise.all([promoBoxPromise, ...batchPromises])

  const promoBox =
    promoBoxResponse && isResponseSuccessful(promoBoxResponse) ? promoBoxResponse.promo_box : null
  const blocks = batches
    .filter(isResponseSuccessful)
    .map(result => result.blocks)
    .flat()

  return {
    homepage_session_id: homepageSessionId,
    promo_box: promoBox,
    blocks,
  }
}
