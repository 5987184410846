'use client'

import { PropsWithChildren, useMemo, useState } from 'react'

import HomeContext, { HomeContextType } from './HomeContext'

const HomeProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [homepageSessionId, setHomepageSessionId] = useState<HomeContextType['homepageSessionId']>()

  const value = useMemo(
    () => ({
      homepageSessionId,
      setHomepageSessionId,
    }),
    [homepageSessionId],
  )

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export default HomeProvider
