'use client'

import { Button, Cell, Image, Dialog, Spacer, Text } from '@vinted/web-ui'

import { CATALOG_URL } from 'constants/routes'
import useImageAssetsUrl from 'hooks/useImageAssetsUrl'
import useTranslate from 'hooks/useTranslate'

const ErrorModal = () => {
  const translate = useTranslate('homepage.error')

  const assetUrl = useImageAssetsUrl()

  return (
    <Dialog show closeOnOverlay={false} testId="homepage-error-modal">
      <Cell>
        <div className="u-flexbox u-justify-content-center">
          <Image src={assetUrl('exclamation-red.svg')} size={Image.Size.Large} />
        </div>
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={translate('title')}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Heading}
          as="h2"
        />
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={translate('body')}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Title}
          as="p"
        />
      </Cell>
      <Cell>
        <Button text={translate('cta')} url={CATALOG_URL} styling={Button.Styling.Filled} />
      </Cell>
    </Dialog>
  )
}

export default ErrorModal
