'use client'

import { useEffect } from 'react'
import { isEmpty } from 'lodash'

import useExposeBuyerProtectionFeeDiscountTest from 'hooks/useExposeBuyerProtectionFeeDiscountTest'
import ContentLoader from 'components/ContentLoader'
import ErrorBoundary from 'components/ErrorBoundary'
import SellerPromotion from 'components/SellerPromotion'

import useFetchHomepageBlocks from './hooks/useFetchHomepageBlocks'
import { logHomeError, incrementPageLoadCounter } from './utils/observability'
import BlockArrangement from './common/BlockArrangement'
import ErrorModal from './common/ErrorModal'
import HomeTabs from './HomeTabs'
import HomeBlocks from './HomeBlocks'
import HomeBanners from './HomeBanners'
import Feed from './Feed'
import useCurrentTab from './hooks/useCurrentTab'
import HomeProvider from './HomeProvider/HomeProvider'

type Props = {
  visitsCount: number
}

const Home = ({ visitsCount }: Props) => {
  const currentTab = useCurrentTab()
  const { blocks, areBlocksLoading, refetchBlocks } = useFetchHomepageBlocks()
  const homepageSessionId = blocks?.homepageSessionId

  useExposeBuyerProtectionFeeDiscountTest()

  useEffect(() => {
    incrementPageLoadCounter('initiated', currentTab.name)
  }, [currentTab.name])

  const handleEmptyFeed = () => {
    const isAllContentEmpty = !areBlocksLoading && isEmpty(blocks?.blocks)

    if (isAllContentEmpty) incrementPageLoadCounter('failure', currentTab.name)
  }

  return (
    <>
      <HomeTabs onTabClick={tab => refetchBlocks({ tab, homepageSessionId })} />
      {currentTab.isSellerPromotionEnabled && <SellerPromotion visitsCount={visitsCount} />}
      <div className="container">
        <div className="row u-position-relative">
          <section id="content" className="site-content__content-section grid12">
            <div className="body-content">
              <HomeBanners />
              {!areBlocksLoading && blocks && <HomeBlocks blocks={blocks} />}
              {areBlocksLoading ? (
                <ContentLoader
                  size={ContentLoader.Size.Large}
                  styling={ContentLoader.Styling.Tight}
                  testId="homepage-loader"
                />
              ) : (
                <BlockArrangement afterBlockSpacer={false}>
                  <Feed onEmpty={handleEmptyFeed} />
                </BlockArrangement>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

const WrappedHome = (props: ComponentProps<typeof Home>) => (
  <ErrorBoundary FallbackComponent={ErrorModal} preventLog onError={logHomeError}>
    <HomeProvider>
      <Home {...props} />
    </HomeProvider>
  </ErrorBoundary>
)

export default WrappedHome
