'use client'

import { Fragment } from 'react'

import { BlockEntityType, ThumbnailsBlockStyle } from 'constants/home'
import {
  HomepageBlockEntityModel,
  HomepageItemBlockModel,
  HomepageBlocksModel,
} from 'types/models/homepage-blocks'
import { BannersBlockModel, ItemsCardsBlockModel, ThumbnailsBlockModel } from 'types/models'
import useStickyPromoBox from 'libs/common/braze/hooks/useStickyPromoBox'
import ExposureBlock from 'components/ExposureBlock'

import ItemsBlock from './ItemsBlock'
import { HorizontalRowsBlock, ThumbnailsBlock, BannersBlock, ItemsCardsBlock } from './Layouts'
import BlockArrangement from '../common/BlockArrangement'
import useHomeInsertsVisibility from '../hooks/useHomeInsertsVisibility'

type Props = {
  blocks: HomepageBlocksModel
}

const HomeBlocks = ({ blocks }: Props) => {
  const { isStickyPromoBoxEnabled } = useHomeInsertsVisibility()
  const { getStickyPromoBox } = useStickyPromoBox(blocks.promoBox)

  const renderHomePageGenericBlock = (block: HomepageItemBlockModel, index: number) => {
    if (!blocks) return null

    return (
      <BlockArrangement>
        <ItemsBlock
          {...block}
          promoBox={isStickyPromoBoxEnabled ? getStickyPromoBox() : null}
          homepageSessionId={blocks.homepageSessionId}
          position={index + 1}
        />
      </BlockArrangement>
    )
  }

  const renderThumbnailsBlock = (block: ThumbnailsBlockModel, index: number) => {
    if (!blocks || !block.elements.length) return null

    if (block.style === ThumbnailsBlockStyle.TwoHorizontalRows) {
      return (
        <BlockArrangement>
          <HorizontalRowsBlock
            {...block}
            position={index + 1}
            homepageSessionId={blocks.homepageSessionId}
          />
        </BlockArrangement>
      )
    }

    return (
      <BlockArrangement>
        <ThumbnailsBlock
          {...block}
          homepageSessionId={blocks.homepageSessionId}
          position={index + 1}
        />
      </BlockArrangement>
    )
  }

  const renderBannersBlock = (block: BannersBlockModel, index: number) => {
    if (!blocks || !block.elements.length) return null

    return (
      <BlockArrangement>
        <BannersBlock
          {...block}
          position={index + 1}
          homepageSessionId={blocks.homepageSessionId}
        />
      </BlockArrangement>
    )
  }

  const renderItemsCardsBlock = (block: ItemsCardsBlockModel, index: number) => {
    if (!blocks || !block.cards.length) return null

    return (
      <BlockArrangement>
        <ItemsCardsBlock
          {...block}
          position={index + 1}
          homepageSessionId={blocks.homepageSessionId}
        />
      </BlockArrangement>
    )
  }

  const renderBlock = (block: HomepageBlockEntityModel, index: number) => {
    switch (block.type) {
      case BlockEntityType.ItemBoxBlock:
        return renderHomePageGenericBlock(block.entity, index)
      case BlockEntityType.ThumbnailsBlock:
        return renderThumbnailsBlock(block.entity, index)
      case BlockEntityType.ExposureBlock:
        return <ExposureBlock {...block.entity} />
      case BlockEntityType.ItemsCardsBlock:
        return renderItemsCardsBlock(block.entity, index)
      case BlockEntityType.BannersBlock:
        return renderBannersBlock(block.entity, index)
      default:
        return null
    }
  }

  if (!blocks?.blocks.length) return null

  return blocks.blocks.map((block, index) => {
    const isExposureBlock = block.type === BlockEntityType.ExposureBlock
    const key = isExposureBlock ? block.entity.test_id : index

    return <Fragment key={key}>{renderBlock(block, index)}</Fragment>
  })
}

export default HomeBlocks
