import { useState, useCallback, useRef } from 'react'
import { isEmpty } from 'lodash'

import { FeedEventDto } from 'types/dtos'
import { getFeedEvents } from 'data/api'
import { endOfListEvent } from 'libs/common/event-tracker/events'

import useLatestCallback from 'hooks/useLatestCallback'
import useTracking from 'hooks/useTracking'

import useTabs from '../../hooks/useTabs'

type Props = {
  onEmpty: () => void
}

const useFetchFeedItems = ({ onEmpty }: Props) => {
  const { track } = useTracking()
  const onEmptyMemoized = useLatestCallback(onEmpty)
  const { currentTab } = useTabs()

  const [events, setEvents] = useState<Array<FeedEventDto>>([])
  const [maxScore, setMaxScore] = useState<number | null>(null)
  const [endReached, setEndReached] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false)

  const pagesFetched = useRef(0)
  const isFirstPageFetched = pagesFetched.current > 0

  const fetchFeedEvents = useCallback(async () => {
    if (isLoading || endReached) return null

    setIsLoading(true)

    const isFirstFetch = !events.length
    const props = isFirstFetch ? {} : { maxScore, itemCount: events.length }
    const response = await getFeedEvents({ ...props, tabName: currentTab.name })

    if ('errors' in response) {
      if (isFirstFetch) onEmptyMemoized()
      setEndReached(true)
      setIsLoading(false)

      return null
    }

    const {
      feed_events: newEvents,
      show_load_more_button: loadMore,
      max_score: newMaxScore,
    } = response

    if (isEmpty(newEvents)) {
      if (isFirstFetch) onEmptyMemoized()
      setEndReached(true)
      setIsLoading(false)

      track(endOfListEvent({ itemCount: events.length }))

      return null
    }

    setMaxScore(newMaxScore)
    setEvents(prevArray => [...prevArray, ...newEvents])
    setIsLoading(false)
    setShowLoadMoreButton(loadMore)

    pagesFetched.current += 1

    return events.length + newEvents.length
  }, [events.length, maxScore, track, isLoading, currentTab, onEmptyMemoized, endReached])

  return {
    events,
    fetchFeedEvents,
    endReached,
    isLoading,
    showLoadMoreButton,
    isFirstPageFetched,
  }
}

export default useFetchFeedItems
