export enum Portal {
  De = 'de',
  DeBabies = 'de_babies',
  Fr = 'fr',
  Lt = 'lt',
  LtBabies = 'lt_babies',
  Es = 'es',
  Nl = 'nl',
  Pl = 'pl',
  Cz = 'cz',
  Uk = 'uk',
  Us = 'us',
  Sb = 'sb',
}
