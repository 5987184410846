export enum Banner {
  ClosetPromotion = 'closet_promotion',
  FeedPersonalizationBanner = 'feed_personalization_banner',
  Nps = 'nps',
  EmailConfirmation = 'email_confirmation',
  FullNameConfirmation = 'full_name_confirmation',
  PushUpBanner = 'push_up_banner',
  RecommendedSearchesViewCount = 'recommended_searches_view_count',
  TermsAndConditions = 'terms_and_conditions',
  PortalMergeFeed = 'portal_merge_feed',
  PortalMergeSourceAnnouncement = 'portal_merge_source_announcement',
  PortalMergeWelcomeScreen = 'portal_merge_welcome_screen',
  PortalMergeDataMigration = 'portal_merge_data_migration',
  ListerActivation = 'lister_activation',
  OnboardingModal = 'onboarding_modal',
  BusinessAccount = 'business_account',
  CatalogRules = 'catalog_rules',
}
