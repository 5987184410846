'use client'

import { useSelector } from 'react-redux'

import PersonalizationBanner from 'components/PersonalizationBanner'
import { getBanners } from 'state/banners/selectors'
import useTranslate from 'hooks/useTranslate'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking'
import useFetchClosets from 'hooks/useFetchClosetPromotions'

import BlockTitle from '../common/BlockTitle'
import DynamicSpacer from '../common/DynamicSpacer'
import ItemsLoader from './components/ItemsLoader'
import ItemsGrid from './components/ItemsGrid'
import useFetchItems from './hooks/useFetchFeedItems'
import useCurrentTab from '../hooks/useCurrentTab'

type Props = {
  onEmpty: () => void
}

const Feed = ({ onEmpty }: Props) => {
  const banners = useSelector(getBanners)

  const currentTab = useCurrentTab()
  const translate = useTranslate()
  const { track } = useTracking()

  const { events, endReached, isLoading, showLoadMoreButton, fetchFeedEvents, isFirstPageFetched } =
    useFetchItems({
      onEmpty,
    })
  const { closets, fetchClosetPromo } = useFetchClosets({
    isEnabled: currentTab.feed.areInsertsEnabled,
  })

  function handleLoadMoreButtonClick() {
    track(clickEvent({ target: ClickableElement.FeedLoadMoreButton }))

    fetchFeedEvents()
    fetchClosetPromo(events.length)
  }

  async function handlePageEnd() {
    const isFirstFetch = !isFirstPageFetched
    const newItemCount = await fetchFeedEvents()

    if (isFirstFetch && newItemCount) {
      fetchClosetPromo(newItemCount)
    }
  }

  function renderPersonalizationBanner() {
    if (!endReached || !banners.feedPersonalizationBanner) return null

    return <PersonalizationBanner banner={banners.feedPersonalizationBanner} />
  }

  return (
    <>
      {isFirstPageFetched && (
        <>
          <BlockTitle title={translate(currentTab.feed.titleKey)} />
          <DynamicSpacer phones="Regular" tabletsUp="Large" />
        </>
      )}
      <ItemsGrid events={events} closets={closets} />
      <ItemsLoader
        endReached={endReached}
        isLoading={isLoading}
        showLoadMoreButton={showLoadMoreButton}
        handleLoadMoreButtonClick={handleLoadMoreButtonClick}
        onPageEnd={handlePageEnd}
      />
      {renderPersonalizationBanner()}
    </>
  )
}

export default Feed
