'use client'

import { ReactNode } from 'react'
import { Cell, Text } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'

import HorizontalScrollArea from 'components/HorizontalScrollArea'

type Props = {
  url?: string
  onClick?: () => void
  onEnter?: () => void
  content: ReactNode
  testId?: string
}

const CtaItem = ({ url, onClick, onEnter, content, testId }: Props) => {
  const renderCell = () => (
    <div className="horizontally-scrollable-items__cta-content">
      <Cell
        url={url}
        onClick={onClick}
        type={Cell.Type.Navigating}
        theme="transparent"
        testId={testId}
      >
        <Text
          type={Text.Type.Title}
          theme="muted"
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
          text={content}
        />
      </Cell>
    </div>
  )

  const handleChange = (inView: boolean) => inView && onEnter?.()

  return (
    <HorizontalScrollArea.Item className="horizontally-scrollable-items__item">
      {onEnter ? (
        <InView className="u-fill-height" onChange={handleChange}>
          {renderCell()}
        </InView>
      ) : (
        renderCell()
      )}
    </HorizontalScrollArea.Item>
  )
}

export default CtaItem
