import { useState, useCallback } from 'react'
import { isEmpty, sum } from 'lodash'
import uuid from 'uuid'

import { ClosetModel } from 'types/models'
import { getClosetPromotions } from 'data/api'
import { transformClosets } from 'data/transformers/closet'
import { CLOSET_PROMOTIONS_ITEM_COUNT } from 'constants/index'
import { systemTraceClosetPromoEvent } from 'libs/common/event-tracker/events'
import { getFirstListedBreakpoint } from 'components/Breakpoint'
import { Screen } from 'constants/tracking/screens'
import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import useAbTest from 'hooks/useAbTest'

import { feedInsertPositions } from '../pages/Home/Feed/constants'

type Props = {
  isEnabled: boolean
}

const useFetchClosets = ({ isEnabled }: Props) => {
  const breakpoints = useBreakpoint()
  const { track } = useTracking()
  const isVasHarmEnabled =
    useAbTest({ abTestName: 'vas_harm_v2', shouldTrackExpose: true })?.variant === 'on'

  const [closets, setClosets] = useState<Array<ClosetModel>>([])

  const fetchClosetPromo = useCallback(
    async (itemCount: number) => {
      const tabletsUpBreakpoint =
        getFirstListedBreakpoint(breakpoints.active, ['wide', 'desktops']) || 'tablets'

      const shouldFetch =
        isEnabled &&
        !isVasHarmEnabled &&
        itemCount >= feedInsertPositions.first[tabletsUpBreakpoint]

      if (!shouldFetch) return

      const userIds = closets.map(closet => closet.user.id)
      const trackingUuid = uuid.v4()

      const breakpoint = getFirstListedBreakpoint(breakpoints.active) || 'phones'

      const breakpointToClosetPromoCount = {
        wide: 3,
        desktops: 4,
        tablets: 5,
        phones: 5,
      }

      const closetPromoCount = breakpointToClosetPromoCount[breakpoint]

      track(
        systemTraceClosetPromoEvent({
          uuid: trackingUuid,
          type: 'frontend_sent',
          closetCount: closetPromoCount,
          itemCount: CLOSET_PROMOTIONS_ITEM_COUNT,
        }),
      )

      const response = await getClosetPromotions({
        perPage: closetPromoCount,
        itemCount: CLOSET_PROMOTIONS_ITEM_COUNT,
        excludedUserIds: userIds,
        feedItemsOnly: true,
        screenName: Screen.Homepage,
      })

      if ('errors' in response) return

      const transformedClosets = transformClosets(response.promoted_closets)

      track(
        systemTraceClosetPromoEvent({
          uuid: trackingUuid,
          type: 'frontend_received_success',
          closetCount: transformedClosets.length,
          itemCount: sum(transformedClosets.map(closet => closet.items.length)),
        }),
      )

      if (isEmpty(transformedClosets)) return

      const isFirstFetch = !closets.length && transformedClosets.length > 0

      if (isFirstFetch) {
        transformedClosets[0]!.showBanner = true
      }

      setClosets(prevArray => [...prevArray, ...transformedClosets])
    },
    [breakpoints.active, track, closets, isEnabled, isVasHarmEnabled],
  )

  return { closets, fetchClosetPromo }
}

export default useFetchClosets
