'use client'

import { useRef } from 'react'
import { Tabs } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { scrollToTop } from 'libs/utils/window'
import useTracking from 'hooks/useTracking/useTracking'
import { userClickHomepageVertical } from 'libs/common/event-tracker/events'

import { TabConfig, TabName } from '../types'
import useTabs from '../hooks/useTabs'
import { scrollToTab } from '../utils/tabs'
import { useHomeContext } from '../HomeProvider'
import { TABS } from '../constants'

type Props = {
  onTabClick: (tabName: TabConfig) => void
}

const HomeTabs = ({ onTabClick }: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()
  const { currentTab, shouldShowTabs, navigateToTab, tabs } = useTabs()
  const containerRef = useRef<HTMLDivElement>(null)
  const { homepageSessionId } = useHomeContext()

  if (!shouldShowTabs) return null

  const handleClick = (tabName: TabName) => {
    scrollToTab(tabName, containerRef.current)
    scrollToTop()
    track(
      userClickHomepageVertical({
        homepageSessionId,
        target: tabName,
      }),
    )

    onTabClick(TABS[tabName])

    if (tabName === currentTab.name) return

    navigateToTab(tabName)
  }

  const tabItems: Array<{ id: TabName; title: string }> = Object.values(tabs).map(tab => ({
    id: tab.name,
    title: translate(tab.titleKey),
  }))

  return (
    <div className="homepage-tabs">
      <div className="homepage-tabs__content" ref={containerRef}>
        <Tabs
          items={tabItems}
          activeItemId={currentTab.name}
          onClick={item => handleClick(item.id)}
        />
      </div>
    </div>
  )
}

export default HomeTabs
