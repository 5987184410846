import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getUser, getUserCountryCode } from 'state/session/selectors'
import { CountryCode } from 'constants/country'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'

const MAX_VISITS_FOR_PROMO = 5
const COUNTRIES_FOR_NO_PROMO = [CountryCode.Lt, CountryCode.De, CountryCode.At]

export const useSellerPromotion = (visitsCount: number) => {
  const user = useSelector(getUser)
  const userCountryCode = useSelector(getUserCountryCode) || ''

  const isPortalMergeSourceEnabled = useSelector(getIsFeatureSwitchEnabled('portal_merge_source'))
  const cookie = useCookie()

  const countryCodeLowerCase = userCountryCode.toLowerCase() as CountryCode
  const isNoPromoCountry = COUNTRIES_FOR_NO_PROMO.includes(countryCodeLowerCase)
  const isPromotionDisabled = isNoPromoCountry || isPortalMergeSourceEnabled
  const isAnonymous = !user
  const isUserEligibleForPromotion = user && !user.item_count && visitsCount <= MAX_VISITS_FOR_PROMO

  const showSellerPromotion = !isPromotionDisabled && (isAnonymous || isUserEligibleForPromotion)

  useEffect(() => {
    if (!user) return
    if (!showSellerPromotion) return

    cookie.set(cookiesDataByName.seller_header_visits, String(visitsCount + 1))
  }, [user, visitsCount, showSellerPromotion, cookie])

  return {
    showSellerPromotion,
  }
}
