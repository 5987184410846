import { useEffect, useRef } from 'react'

import useFetch from 'hooks/useFetch'
import { transformHomepageBlockResponse } from 'data/api/transformers/response'

import { getParallelHomepageBlocks } from '../utils/api'
import useTabs from './useTabs'
import { useHomeContext } from '../HomeProvider'

const useFetchHomepageBlocks = () => {
  const { currentTab } = useTabs()
  const initialTab = useRef(currentTab)
  const { setHomepageSessionId } = useHomeContext()

  const {
    fetch: fetchHomepageBlocks,
    transformedData: blocks,
    isLoading,
    error,
  } = useFetch(getParallelHomepageBlocks, transformHomepageBlockResponse, {
    clearDataOnFetch: true,
  })

  useEffect(() => {
    const fetchBlocks = async () => {
      const response = await fetchHomepageBlocks({ tab: initialTab.current })
      const sessionId = response.transformedData?.homepageSessionId

      if (sessionId) setHomepageSessionId(sessionId)
    }

    fetchBlocks()
  }, [fetchHomepageBlocks, setHomepageSessionId])

  return {
    blocks: error ? undefined : blocks,
    refetchBlocks: fetchHomepageBlocks,
    areBlocksLoading: (!blocks && !error) || isLoading,
    error,
  }
}

export default useFetchHomepageBlocks
