'use client'

import { Component } from 'react'
import { Button, Text } from '@vinted/web-ui'

import Banner from 'components/Banner'
import { setBannerAsSeen } from 'data/api'
import { PortalMergeDataMigrationBannerModel } from 'types/models'

type Props = {
  banner: PortalMergeDataMigrationBannerModel
}

type State = { isVisible: boolean }

class PortalMergeDataMigrationBanner extends Component<Props, State> {
  state: Readonly<State> = { isVisible: true }

  handleCtaClick = () => {
    setBannerAsSeen({ type: 'portal_merge_data_migration', name: 'portal_merge_user' })

    this.setState({ isVisible: false })
  }

  render() {
    const { heading, text, ctaTitle } = this.props.banner

    if (!this.state.isVisible) return null

    return (
      <Banner
        title={<Text text={heading} type={Text.Type.Heading} />}
        body={<Text text={text} html />}
        actions={[
          <Button text={ctaTitle} styling={Button.Styling.Filled} onClick={this.handleCtaClick} />,
        ]}
      />
    )
  }
}

export default PortalMergeDataMigrationBanner
