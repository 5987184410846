'use client'

import { Button, Cell, Spacer } from '@vinted/web-ui'

import { ITEM_UPLOAD_URL } from 'constants/routes'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  primaryText: string
  secondaryText: string
  onPrimaryClick: () => void
  onSecondaryClick: () => void
}

const OnboardingTextActions = ({
  primaryText,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
}: Props) => {
  const breakpoints = useBreakpoint()

  return (
    <Cell styling={breakpoints.phones ? Cell.Styling.Narrow : Cell.Styling.Default}>
      <Button
        styling={Button.Styling.Filled}
        text={primaryText}
        url={ITEM_UPLOAD_URL}
        onClick={onPrimaryClick}
      />
      <div className="u-tablets-up-only">
        <Spacer size={Spacer.Size.Medium} />
      </div>
      <div className="u-phones-only">
        <Spacer size={Spacer.Size.Regular} />
      </div>
      <Button text={secondaryText} onClick={onSecondaryClick} />
    </Cell>
  )
}

export default OnboardingTextActions
