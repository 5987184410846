'use client'

import { Button, Text } from '@vinted/web-ui'

import Banner from 'components/Banner'
import useTracking from 'hooks/useTracking'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { PortalMergeFeedBannerModel } from 'types/models'
import { clickEvent } from 'libs/common/event-tracker/events'

type Props = {
  banner: PortalMergeFeedBannerModel
  screen?: Screen
}

const PortalMergeFeedBanner = ({ banner, screen }: Props) => {
  const { track } = useTracking()

  function handleButtonClick() {
    track(
      clickEvent({
        target: ClickableElement.MergeAnnouncementAction,
        screen,
      }),
    )
  }

  const { heading, text, buttonTitle, buttonUrl } = banner

  return (
    <Banner
      title={<Text text={heading} type={Text.Type.Heading} />}
      body={<Text text={text} html />}
      actions={[
        <Button
          text={buttonTitle}
          url={buttonUrl}
          styling={Button.Styling.Filled}
          onClick={handleButtonClick}
        />,
      ]}
    />
  )
}

export default PortalMergeFeedBanner
