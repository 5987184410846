'use client'

import { ChangeEvent, useState } from 'react'
import { Button, Text, Validation, Spacer } from '@vinted/web-ui'
import { isEmpty } from 'lodash'

import useTranslate from 'hooks/useTranslate'

import { DeprecatedInputText } from 'components/Input'
import Banner from 'components/Banner'

import { UiState } from 'constants/ui'
import { FULL_NAME } from 'constants/regexes'
import { FullNameConfirmationBannerModel } from 'types/models'
import { updateCurrentUserRealName } from 'data/api'

type Props = {
  banner: FullNameConfirmationBannerModel
}

const FullNameConfirmationBanner = ({ banner: data }: Props) => {
  const translate = useTranslate('full_name_banner')

  const [fullName, setFullName] = useState<string>(data.realName)
  const [fullNameValidationMessage, setFullNameValidationMessage] = useState<string>('')
  const [status, setStatus] = useState<UiState>(UiState.Idle)

  const validateFullName = (): boolean => {
    if (isEmpty(fullName)) {
      setFullNameValidationMessage(translate('validation.empty_full_name'))

      return false
    }

    if (!FULL_NAME.test(fullName)) {
      setFullNameValidationMessage(translate('validation.wrong_full_name_format'))

      return false
    }

    return true
  }

  const handleSubmitButtonClick = async () => {
    if (!validateFullName()) return

    setStatus(UiState.Pending)

    const response = await updateCurrentUserRealName({ realName: fullName, target: 'feed_banner' })

    if ('errors' in response) return

    setStatus(UiState.Success)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value)
    setFullNameValidationMessage('')
  }

  const renderBanner = () => {
    const showBanner = status === UiState.Idle || status === UiState.Pending

    if (!showBanner) return null

    const { title, subtitle, placeholder, buttonTitle } = data

    const tip = <Validation text={translate('tip')} />
    const validationMessage = <Validation text={fullNameValidationMessage} theme="warning" />

    return (
      <Banner
        title={<Text text={title} type={Text.Type.Heading} />}
        body={
          <>
            <Spacer size={Spacer.Size.XSmall} />
            <Text text={subtitle} />
            <Spacer size={Spacer.Size.Medium} />
            <DeprecatedInputText
              onBlur={validateFullName}
              styling="tight"
              name="real_name"
              type="text"
              maxLength={50}
              value={fullName}
              placeholder={placeholder}
              onChange={handleInputChange}
              validation={isEmpty(fullNameValidationMessage) ? tip : validationMessage}
            />
          </>
        }
        actions={[
          <Button
            text={buttonTitle}
            styling={Button.Styling.Filled}
            onClick={handleSubmitButtonClick}
          />,
        ]}
      />
    )
  }

  if (status === UiState.Success) return null

  return renderBanner()
}

export default FullNameConfirmationBanner
